import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Colors } from '../constants/Colors';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import CallIcon from '@mui/icons-material/Call';
import PaidIcon from '@mui/icons-material/Paid';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ChecklistIcon from '@mui/icons-material/Checklist';
export default function DrawerComponent({ onCloseDrawer, visible, activeItem }) {
    const [hoveringOverDrawerItem, setHoveringOverDrawerItem] = useState({
        bool: false,
        item: ''
    });
    const [navigate, setNavigate] = useState({
        bool: false,
        path: ''
    });

    const styles = {
        innerDrawerContainer: {
            marginVertical: 30, 
            display: 'flex', 
            flexDirection: 'column', 
            padding: 5
        },
        activelistItem: {
            backgroundColor: Colors.primary300, 
            width: '100%', 
            height: '3em',
            borderRadius: 5,
            marginBottom: 5
        },
    }

    if (navigate.bool) {
        return <Navigate to={navigate.path} replace={true}/>
    }

    return (
        <Drawer open={visible} onClose={onCloseDrawer}>
            <div style={styles.innerDrawerContainer}>
                <img src={require('../assets/breddilogo.png')} style={{width: 300, margin: 15}}/>
                <List>
                <ListItem onClick={() =>  activeItem === 'home' ? {} : setNavigate({bool: true, path: '/'})} style={{ marginBottom: 5, width: '100%', height: '3em', borderRadius: 5, opacity: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'home' && activeItem !== 'home') && 0.4, cursor: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'home' && activeItem !== 'home') && 'pointer'}} onMouseEnter={() => activeItem !== 'home' && setHoveringOverDrawerItem({
                        bool: true,
                        item: 'home'
                    })} onMouseLeave={() => setHoveringOverDrawerItem(false)}>                        
                        <ListItemText style={{textAlign: 'left', color: 'gray', fontWeight: 'bold'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <HomeIcon  style={{paddingRight: 10, color: activeItem === 'home' ? Colors.primary1400 : 'gray'}}/>
                            <p style={{fontWeight: '500', fontSize: 18, marginRight: 3, color: activeItem === 'home' ? Colors.primary1400 : 'gray', marginTop: 20}}>Home</p>
                        </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() =>  activeItem === 'about' ? {} : setNavigate({bool: true, path: '/about'})} style={{ marginBottom: 5, width: '100%', height: '3em', borderRadius: 5, opacity: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'about' && activeItem !== 'about') && 0.4, cursor: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'about' && activeItem !== 'about') && 'pointer'}} onMouseEnter={() => activeItem !== 'about' && setHoveringOverDrawerItem({
                        bool: true,
                        item: 'about'
                    })} onMouseLeave={() => setHoveringOverDrawerItem(false)}>                        
                        <ListItemText style={{textAlign: 'left', color: 'gray', fontWeight: 'bold'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <InfoIcon  style={{paddingRight: 10, color: activeItem === 'about' ? Colors.primary1400 : 'gray'}}/>
                            <p style={{fontWeight: '500', fontSize: 18, marginRight: 3, color: activeItem === 'about' ? Colors.primary1400 : 'gray', marginTop: 19}}>About</p>
                        </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() =>  activeItem === 'contact' ? {} : setNavigate({bool: true, path: '/contact'})} style={{ marginBottom: 5, width: '100%', height: '3em', borderRadius: 5, opacity: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'contact' && activeItem !== 'contact') && 0.4, cursor: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'contact' && activeItem !== 'contact') && 'pointer'}} onMouseEnter={() => activeItem !== 'contact' && setHoveringOverDrawerItem({
                        bool: true,
                        item: 'contact'
                    })} onMouseLeave={() => setHoveringOverDrawerItem(false)}>                        
                        <ListItemText style={{textAlign: 'left', color: 'gray', fontWeight: 'bold'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <CallIcon  style={{paddingRight: 10, color: activeItem === 'contact' ? Colors.primary1400 : 'gray'}}/>
                            <p style={{fontWeight: '500', fontSize: 18, marginRight: 3, color: activeItem === 'contact' ? Colors.primary1400 : 'gray', marginTop: 19}}>Contact</p>
                        </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() =>  activeItem === 'pt' ? {} : setNavigate({bool: true, path: '/past-transactions'})} style={{ marginBottom: 5, width: '100%', height: '3em', borderRadius: 5, opacity: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'pt' && activeItem !== 'pt') && 0.4, cursor: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'pt' && activeItem !== 'pt') && 'pointer'}} onMouseEnter={() => activeItem !== 'pt' && setHoveringOverDrawerItem({
                        bool: true,
                        item: 'pt'
                    })} onMouseLeave={() => setHoveringOverDrawerItem(false)}>                        
                        <ListItemText style={{textAlign: 'left', color: 'gray', fontWeight: 'bold'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <PaidIcon  style={{paddingRight: 10, color: activeItem === 'pt' ? Colors.primary1400 : 'gray'}}/>
                            <p style={{fontWeight: '500', fontSize: 18, marginRight: 3, color: activeItem === 'pt' ? Colors.primary1400 : 'gray', marginTop: 20}}>Past Transactions</p>
                        </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() =>  activeItem === 'hs' ? {} : setNavigate({bool: true, path: '/home-services'})} style={{ marginBottom: 5, width: '100%', height: '3em', borderRadius: 5, opacity: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'hs' && activeItem !== 'hs') && 0.4, cursor: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'hs' && activeItem !== 'hs') && 'pointer'}} onMouseEnter={() => activeItem !== 'hs' && setHoveringOverDrawerItem({
                        bool: true,
                        item: 'hs'
                    })} onMouseLeave={() => setHoveringOverDrawerItem(false)}>                        
                        <ListItemText style={{textAlign: 'left', color: 'gray', fontWeight: 'bold'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <CleaningServicesIcon  style={{paddingRight: 10, color: activeItem === 'hs' ? Colors.primary1400 : 'gray'}}/>
                            <p style={{fontWeight: '500', fontSize: 18, marginRight: 3, color: activeItem === 'hs' ? Colors.primary1400 : 'gray', marginTop: 20}}>Home Services</p>
                        </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() =>  activeItem === 'cl' ? {} : setNavigate({bool: true, path: '/listings'})} style={{ marginBottom: 5, width: '100%', height: '3em', borderRadius: 5, opacity: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'cl' && activeItem !== 'cl') && 0.4, cursor: (hoveringOverDrawerItem.bool && hoveringOverDrawerItem.item === 'cl' && activeItem !== 'cl') && 'pointer'}} onMouseEnter={() => activeItem !== 'cl' && setHoveringOverDrawerItem({
                        bool: true,
                        item: 'cl'
                    })} onMouseLeave={() => setHoveringOverDrawerItem(false)}>                        
                        <ListItemText style={{textAlign: 'left', color: 'gray', fontWeight: 'bold'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <ChecklistIcon  style={{paddingRight: 10, color: activeItem === 'cl' ? Colors.primary1400 : 'gray'}}/>
                            <p style={{fontWeight: '500', fontSize: 18, marginRight: 3, color: activeItem === 'cl' ? Colors.primary1400 : 'gray', marginTop: 20}}>Current Listings</p>
                        </div>
                        </ListItemText>
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
};