import { useState, useEffect } from 'react';
import { Avatar, IconButton, Button } from '@mui/material';
import DrawerComponent from '../../componenets/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import EmptyButton from '../../componenets/UI/EmptyButton';
import { Navigate, Link } from 'react-router-dom';
import LoadingOverlay from '../../componenets/LoadingOverlay';
import ConstructionIcon from '@mui/icons-material/Construction';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { Colors } from '../../constants/Colors';
import LaunchIcon from '@mui/icons-material/Launch';
import { Call } from '@mui/icons-material';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GrassIcon from '@mui/icons-material/Grass';
import HomeIcon from '@mui/icons-material/Home';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';

export default function HomeServicesScreen() {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [navigate, setNavigate] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [hovering, setHovering] = useState({hovering: false, key: ''});
    const open = Boolean(anchorEl);

    const styles = {
        headerLogoContainer: {
            justifyContent: windowSize[0] > 1000 ? 'flex-end' : 'center', 
            width: windowSize[0] > 910 ? '20%' : '100%',
            marginRight: windowSize[0] < 1200 && 62
        },
        card: {
            boxShadow: "5px 5px 10px rgb(0 0 0 / 0.2)",
            backgroundColor: 'white',
            borderRadius: 8,
            height: windowSize[0] < 900 ? 150 : 200, 
            width: windowSize[0] < 900 ? 280 : 475,
            margin: 15
        },
        vibrantText: {
            color: 'black', 
            fontSize: 30,
            fontWeight: 'bold',
            color: Colors.primary1100
        },
    }

    useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    if (navigate !== '') {
        return <Navigate to={navigate}/>
    }



    return (
        <>
        <div>

        <div className='header' style={{position: 'fixed'}}>
        <IconButton style={{marginLeft: 15}} onClick={() => setDrawerIsVisible(true)}>
            <MenuIcon style={{color: 'navy'}}/>
        </IconButton>
        <div style={styles.headerLogoContainer} className='headerLogoContainer'>
            <img src={require('../../assets/breddilogo.png')} style={{width: 175}}/>
        </div>
        <div className='toolbar'> {windowSize[0] > 1000 && 
            <div className='toolbarButtonsContainer'>
                <EmptyButton onClick={() => {
                    setLoading(true);
                    setTimeout(() => {
                        setLoading(true);
                        setNavigate('/')
                    }, 650)
                }} color='navy' >Home</EmptyButton>
                <EmptyButton onClick={() => {
                    setLoading(true);
                    setTimeout(() => setNavigate('/about'), 650)
                    
                }} color='navy' >About</EmptyButton>
                <EmptyButton onClick={() => {
                    setLoading(true);
                    setTimeout(() => setNavigate('/contact'), 650)
                    
                }} color='navy' >Contact</EmptyButton>      
                <EmptyButton onClick={() => {
                    setLoading(true);
                    setTimeout(() => setNavigate('/past-transactions'), 650)
                    
                }} color='navy' >Past Transactions</EmptyButton>                     
                <EmptyButton color='navy'  onClick={() => {
                    setLoading(true);
                    setTimeout(() => setNavigate('/listings'), 650)
                    
                }}>Listings</EmptyButton>
            </div>
            }
        </div>
    </div>
    </div>

    <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1', height: '100%', width: '100vw',}}>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100vw', marginTop: 100}}>
                <p style={styles.vibrantText}>Home Services</p>
            </div>
        <div style={{display: 'flex', flexDirection: 'row', margin: 15, flexWrap: 'wrap', marginTop: windowSize[0] < 800 ? -15 : 40, height: '100%'}}>
            <div style={{flex: 1, justifyContent: 'center', display: 'flex'}}>
                <div style={styles.card}>
                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: '2.5em', height: windowSize[0] < 900 ? 6 : 30}}>
                                    <p style={{fontWeight: '500', color: Colors.primary1600, fontSize: windowSize[0] < 900 ? 19 : 30}}>Handyman</p>
                                    <ConstructionIcon style={{width: windowSize[0] < 900 ? 25 : 35, height: windowSize[0] < 900 ? 25 : 35, color: Colors.primary1400, marginLeft: 5, marginTop: 5}}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: "100%", marginLeft: 31, height: 31}}>
                                    <p style={{fontWeight: '500', fontSize: windowSize[0] < 900 ? 13 : 17}}>Narinder Singh</p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginRight: windowSize[0] < 900 ? 6 : 39}}>
                                    <Button style={{fontSize:  windowSize[0] < 900 ? 11 : 15}} endIcon={<Call style={{marginBottom: 3}}/>}><a className='link'>(913) 313-1666</a></Button>
                                </div>
                            </div>
                            <Avatar src={require('../../assets/handyman.jpg')} style={{width: windowSize[0] < 900 ? 95 : 150, height: windowSize[0] < 900 ? 95 : 150, marginLeft: windowSize[0] > 900 ? '4em' : '0.5em', marginTop: 28}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{flex: 1, justifyContent: 'center', display: 'flex'}}>
            <div style={styles.card}>
                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: '2.5em', height: windowSize[0] < 900 ? 13 : 28, marginRight: 28}}>
                                    <p style={{fontWeight: '500', color: Colors.primary1600, fontSize: windowSize[0] < 900 ? 19 : 30}}>Painting</p>
                                    <FormatPaintIcon style={{width: windowSize[0] < 900 ? 20 : 35, height: windowSize[0] < 900 ? 20 : 35, color: Colors.primary1400, marginLeft: 5, marginTop: 5,}}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: "100%", marginLeft: 31, height: 31}}>
                                    <p style={{fontWeight: '500', fontSize: windowSize[0] > 900 ? 17 : 13}}>Narinder Singh</p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginRight: windowSize[0] > 900 ? 32 : 13, marginLeft: windowSize[0] < 900 && 5}}>
                                    <Button style={{fontSize:  windowSize[0] < 900 ? 11 : 15}} endIcon={<Call style={{marginBottom: 3}}/>}>(913) 313-1666</Button>
                                </div>
                            </div>
                            <Avatar src={require('../../assets/paint.jpg')} style={{width: windowSize[0] < 900 ? 95 : 150, height: windowSize[0] < 900 ? 95 : 150, marginLeft: windowSize[0] > 900 ? '4em' : '0.5em', marginTop: 28}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{flex: 1, justifyContent: 'center', display: 'flex'}}>
            <div style={styles.card}>
                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: '2.5em', height: windowSize[0] < 900 ? 13 : 28, marginRight: 28}}>
                                    <p style={{fontWeight: '500', color: Colors.primary1600, fontSize: windowSize[0] < 900 ? 19 : 30}}>Plumbing</p>
                                    <PlumbingIcon style={{width: windowSize[0] < 900 ? 20 : 35, height: windowSize[0] < 900 ? 20 : 35, color: Colors.primary1400, marginLeft: 5, marginTop: 5,}}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: "100%", marginLeft: 31, height: 31}}>
                                    <p style={{fontWeight: '500', fontSize: windowSize[0] > 900 ? 17 : 13}}>Narinder Singh</p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginRight: windowSize[0] > 900 ? 50 : 13, marginLeft: windowSize[0] < 900 && 5}}>
                                    <Button style={{fontSize:  windowSize[0] < 900 ? 11 : 15}} endIcon={<Call style={{marginBottom: 3}}/>}>(913) 313-1666</Button>
                                </div>
                            </div>
                            <Avatar src={require('../../assets/plumbing.jpg')} style={{width: windowSize[0] < 900 ? 95 : 150, height: windowSize[0] < 900 ? 95 : 150, marginLeft: windowSize[0] > 900 ? '4em' : '0.5em', marginTop: 28}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{flex: 1, justifyContent: 'center', display: 'flex'}}>
            <div style={styles.card}>
                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', width: '100%', marginLeft: 30}}>
                                    <p style={{fontWeight: '500', color: Colors.primary1600, fontSize: windowSize[0] < 900 ? 19 : 30}}>Cleaning</p>
                                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                        <CleaningServicesIcon style={{width: 35, height: 35, color: Colors.primary1400, marginLeft: 5, marginTop: 5}}/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <Button style={{fontSize: windowSize[0] < 900 ? 11 : 15}} endIcon={<LaunchIcon style={{marginBottom: 3}}/>}>Open Website</Button>
                                    <Button style={{fontSize:  windowSize[0] < 900 ? 11 : 15}} endIcon={<Call style={{marginBottom: 3}}/>}>(000) 000-0000</Button>
                                </div>
                            </div>
                            <Avatar src={require('../../assets/cleaning.jpg')} style={{width: windowSize[0] < 900 ? 95 : 150, height: windowSize[0] < 900 ? 95 : 150, marginLeft: windowSize[0] > 900 ? '4em' : '0.6em', marginTop: 20}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{flex: 1, justifyContent: 'center', display: 'flex'}}>
            <div style={styles.card}>
                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', width: '100%', marginLeft: 30}}>
                                    <p style={{fontWeight: '500', color: Colors.primary1600, fontSize: windowSize[0] < 900 ? 19 : 30}}>Inspection</p>
                                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                        <SearchOffIcon style={{width: 35, height: 35, color: Colors.primary1400, marginLeft: 5, marginTop: 5}}/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <Button style={{fontSize: windowSize[0] < 900 ? 11 : 15}} endIcon={<LaunchIcon style={{marginBottom: 3}}/>}>Open Website</Button>
                                    <Button style={{fontSize: windowSize[0] < 900 ? 11 : 15}} endIcon={<Call style={{marginBottom: 3}}/>}>(000) 000-0000</Button>
                                </div>
                            </div>
                            <Avatar src={require('../../assets/plumbing.jpg')} style={{width: windowSize[0] < 900 ? 95 : 150, height: windowSize[0] < 900 ? 95 : 150, marginLeft: windowSize[0] > 900 ? '4em' : '0.6em', marginTop: 20}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{flex: 1, justifyContent: 'center', display: 'flex'}}>
            <div style={styles.card}>
                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', width: '100%', marginLeft: 30}}>
                                    <p style={{fontWeight: '500', color: Colors.primary1600, fontSize: windowSize[0] < 900 ? 19 : 30}}>Landscaping</p>
                                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                        <GrassIcon style={{width: 35, height: 35, color: Colors.primary1400, marginLeft: 5, marginTop: 5}}/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <Button style={{fontSize: windowSize[0] < 900 ? 11 : 15}} endIcon={<LaunchIcon style={{marginBottom: 3}}/>}>Open Website</Button>
                                    <Button style={{fontSize: windowSize[0] < 900 ? 11 : 15}} endIcon={<Call style={{marginBottom: 3}}/>}>(000) 000-0000</Button>
                                </div>
                            </div>
                            <Avatar src={require('../../assets/Grass.jpg')} style={{width: windowSize[0] < 900 ? 95 : 150, height: windowSize[0] < 900 ? 95 : 150, marginLeft: windowSize[0] > 900 ? '3em' : -10, marginTop: 20}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{flex: 1, justifyContent: 'center', display: 'flex'}}>
            <div style={styles.card}>
                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', width: '100%', marginLeft: 30}}>
                                    <p style={{fontWeight: '500', color: Colors.primary1600, fontSize: windowSize[0] < 900 ? 19 : 30}}>Home Loans</p>
                                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                        <HomeIcon style={{width: 35, height: 35, color: Colors.primary1400, marginLeft: 5, marginTop: 5}}/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <Button style={{fontSize: windowSize[0] < 900 ? 11 : 15}} endIcon={<LaunchIcon style={{marginBottom: 3}}/>}>Open Website</Button>
                                    <Button style={{fontSize: windowSize[0] < 900 ? 11 : 15}} endIcon={<Call style={{marginBottom: 3}}/>}>(000) 000-0000</Button>
                                </div>
                            </div>
                            <Avatar src={require('../../assets/homeloan.jpg')} style={{width: windowSize[0] < 900 ? 95 : 150, height: windowSize[0] < 900 ? 95 : 150, marginLeft: windowSize[0] > 900 ? '3em' : -10, marginTop: 20}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{marginTop: 35, flexDirection: 'column', display: 'flex', alignItems: 'center', paddingTop: windowSize[0] < 900 && 30}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center"}}>
                <ReportProblemIcon style={{color: 'gray', marginRight: 5}}/>
                <p style={{fontWeight: '500', fontSize: 19, color: 'gray'}}>DISCLAIMER</p>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '90vw', marginBottom: 15}}>
                <p style={{textAlign: 'center', color: 'gray', fontWeight: "500"}}>
                    The recommendations provided here are solely for informational purposes and should not be considered as professional or expert advice. While every effort has been made to ensure the accuracy and reliability of the information, it is important to note that individual circumstances and preferences may vary. It is strongly advised that you conduct your own research and exercise discretion before making any decisions or taking any actions based on the recommendations provided. The information presented may not be suitable for your specific situation, and any reliance you place on these recommendations is at your own risk.
                </p>
            </div>
        </div>
    </div>
    

    <DrawerComponent visible={drawerIsVisible} activeItem="hs" onCloseDrawer={() => setDrawerIsVisible(false)}/>
    <LoadingOverlay visible={loading} />
    </>
    )
}