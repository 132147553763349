import EmptyButton from "../../componenets/UI/EmptyButton"
import { useState, useEffect } from 'react';
import { Colors } from "../../constants/Colors";
import { Navigate, useLocation } from "react-router-dom";
import "./styles.css"
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import OutlinedButton from "../../componenets/UI/OutlinedButton";
import FormModal from "../../componenets/FormModal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from "@mui/material";
import LoadingOverlay from "../../componenets/LoadingOverlay";
import AlertModal from "../../componenets/AlertModal";

export default function ListingDetailScreen() {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    const [navigate, setNavigate] = useState('');
    const { state } = useLocation();
    const { data } = state;
    const [value, setValue] = useState('1');
    const [hovering, setHovering] = useState('');
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertIsVisible, setAlertIsVisible] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    if (navigate !== '') {
        return <Navigate to={navigate}/>
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const styles = {
    header: {
        flexDirection: 'row', 
        display: 'flex', 
        flex: 1,
        boxShadow: "0px 5px 10px rgb(0 0 0 / 0.2)",
        backgroundColor: 'lightgray',
        opacity: 0.7
    },
    toolbar: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        flex: 1
    },
    toolbarTitle: {
        textAlign: 'center', 
        color: 'navy'
    },
    toolbarButtonsContainer: {
        marginLeft: 50, 
        marginTop: 5
    },
    toolbarButton: {
        marginRight: 15, 
        fontSize: 20,
        color: 'navy'
    },
    vibrantText: {
        color: Colors.primary1100, 
        fontStyle: 'italic',
        fontSize: 25,
        textAlign: 'center'
    }            
}               
function openAlertModalHandler() {
    setModalIsVisible(false);
    setAlertIsVisible(true);
}
    return (
        <>
    <div style={styles.header}>
        <IconButton onClick={() => setNavigate('/listings')}>
            <ArrowBackIcon sx={{color: 'navy', marginTop: 1, marginLeft: 1.5}}></ArrowBackIcon>
        </IconButton>
        <div style={{display: 'flex', justifyContent: windowSize[0] > 1000 ? 'flex-end' : 'center', width: windowSize[0] > 1000 ? '20%' : '75%'}}>
            <img src={require('../../assets/breddilogo.png')} style={{width: 175}}/>
        </div>
        <div style={styles.toolbar}>
            {windowSize[0] > 1000 && <div style={styles.toolbarButtonsContainer}>
            <EmptyButton color='navy'  onClick={() => {
                setLoading(true);
                setTimeout(() => setNavigate('/'), 650)
                
            }}>Home</EmptyButton>
            <EmptyButton onClick={() => {
                setLoading(true);
                setTimeout(() => {
                    setLoading(true);
                    setNavigate('/about')
                }, 650)
            }} color='navy' >About</EmptyButton>
            <EmptyButton onClick={() => {
                setLoading(true);
                setTimeout(() => setNavigate('/contact'), 650)
                
            }} color='navy' >Contact</EmptyButton>
            <EmptyButton onClick={() => {
                setLoading(true);
                setTimeout(() => setNavigate('/past-transactions'), 650)
                
            }} color='navy' >Past Transactions</EmptyButton>      
            <EmptyButton onClick={() => {
                setLoading(true);
                setTimeout(() => setNavigate('/home-services'), 650)
                
            }} color='navy' >Home Services</EmptyButton>  
            </div>}
        </div>
    </div>
    <div style={{overflowX: 'hidden'}}>
    <div className="screenContainer" style={{flexDirection: windowSize[0] < 1200 && 'column'}}>
            <img src={data.img} className="image" style={{width: windowSize[0] < 1200 && 350}}/>
                <div style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 45, borderWidth: 1, borderColor: 'blue'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 20, justifyContent: windowSize[0] < 1200 && 'space-between', marginTop: windowSize[0] < 1200 && 25}}>
                        <p style={{color: 'green', borderRadius: 15, width: windowSize[0] > 1200 && 140, fontSize: windowSize[0] > 1200 ? 40 : 24, fontWeight: '500', marginTop: windowSize[0] < 800 && 28}}>{data.price}</p>
                        <p style={{color: 'gray', marginLeft: windowSize[0] > 1200 && 50, marginTop: 20, fontWeight: '500', fontSize: windowSize[0] < 1200 && 14}}>{data.bedbath}</p>
                        <div style={{marginTop: 7, marginLeft: windowSize[0] > 1200 && 15, marginRight: windowSize[0] < 1200 && 35}}>
                            <OutlinedButton onClick={() => setModalIsVisible(true)}>Interested?</OutlinedButton>
                        </div>
                    </div>
                    <p style={{fontWeight: '500', fontSize: windowSize[0] < 1200 ? 15 : 20, marginTop: windowSize[0] < 1200 && 25}}>{data.address}</p>
                    <TabContext value={value}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Overview" value="1" style={{opacity: hovering === '1' && 0.5}} onMouseEnter={() => value !== '1' && setHovering('1')} onMouseLeave={() => setHovering('')}/>
                            <Tab label="Details" value="2" style={{opacity: hovering === '2' && 0.5}} onMouseEnter={() => value !== '2' && setHovering('2')} onMouseLeave={() => setHovering('')} />
                            <Tab label="Schools" value="3" style={{opacity: hovering === '3' && 0.5}} onMouseEnter={() => value !== '3' && setHovering('3')} onMouseLeave={() => setHovering('')} />
                        </TabList>
                        <div style={{width: 400, height: 200}}>
                            <TabPanel value="1" style={{width: windowSize[0] > 1200 ? 500 : 330}}>
                                <p style={{fontWeight: '500', fontSize: 20}}>
                                    {data.overview}
                                </p>
                            </TabPanel>
                            <TabPanel value="2" style={{width: 500}}>
                                <ul>
                                    <li style={{fontWeight: '500', fontSize: 20}}>Garage: {data.garage}</li>
                                    <li style={{fontWeight: '500', fontSize: 20}}>{data.builddate}</li>
                                    <li style={{fontWeight: '500', fontSize: 20}}>Amenities: {data.amenities}</li>
                                    <li style={{fontWeight: '500', fontSize: 20}}>Basement: {data.basement}</li>
                                    <li style={{fontWeight: '500', fontSize: 20}}>{data.bedbath}</li>
                                    <li style={{fontWeight: '500', fontSize: 20}}>{data.sqft} sq. ft.</li>
                                </ul>
                            </TabPanel>
                            <TabPanel value="3" style={{width: 500}}>
                                <p style={{marginBottom: 15, fontSize: 25, fontWeight: '500'}}>{data.schooldistrict}</p>
                                <p style={{fontSize: 19, fontWeight: '500'}}>{data.elementary}</p>
                                <p style={{fontSize: 19, fontWeight: '500'}}>{data.middleschool}</p>
                                <p style={{fontSize: 19, fontWeight: '500'}}>{data.highschool}</p>
                            </TabPanel>
                        </div>
                    </TabContext>
                </div>
        </div>
    </div>

    <FormModal visible={modalIsVisible} onClose={() => setModalIsVisible(false)} onSubmit={openAlertModalHandler}/>
        <LoadingOverlay visible={loading}/>
        <AlertModal visible={alertIsVisible} onClose={() => setAlertIsVisible(false)} message="Bharthi Reddi has been contacted and will reach out to you soon!" />
    </>
    )
};