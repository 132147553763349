import { Button } from '@mui/material'
import { useState } from 'react'
import { Colors } from '../../constants/Colors';

export default function OutlinedButton({children, onClick}) {
    const [hovering, setHovering] = useState(false);

    const styles = {
        button: {
            borderRadius: 15,
            margin: 10,
            fontWeight: 'bold',
            height: 35,
            backgroundColor: hovering && Colors.primary100
        }
    }

    return (
        <Button onClick={onClick} variant='outlined' style={styles.button} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>{children}</Button>
    )
}