export const Colors = {
    primary100: '#C4EEFF',
    primary300: '#8FDFFF',
    primary500: '#5ED1FF',
    primary700: '#36C6FF',
    primary900: '#00b7ff',
    primary1100: '#0099ff',
    primary1400: '#2076d1',
    primary1600: '#085cb4',
    accent300: '#FF005E'
}