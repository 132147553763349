import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import EmptyButton from '../../componenets/UI/EmptyButton';
import { Navigate } from 'react-router-dom';
import DrawerComponent from '../../componenets/Drawer';
import { Colors } from '../../constants/Colors';
import './styles.css';
import LoadingOverlay from '../../componenets/LoadingOverlay';

export default function ContactScreen() {
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);
    const [navigate, setNavigate] = useState("");
    const [hoveringOverPhone, setHoveringOverPhone] = useState(false);
    const [hoveringEmail, setHoveringEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    if (navigate !== "") {
        return <Navigate to={navigate}/>
    }

    const styles = {
        header: {
            flexDirection: 'row', 
            display: 'flex', 
            flex: 1, 
            boxShadow: "0px 5px 10px rgb(0 0 0 / 0.2)",
            backgroundColor: 'lightgray',
        },
        toolbar: {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            flex: 1
        },
        toolbarTitle: {
            textAlign: 'center', 
            color: 'navy'
        },
        toolbarButtonsContainer: {
            marginLeft: 50, 
            marginTop: 5
        },
        toolbarButton: {
            marginRight: 15, 
            fontSize: 20,
            color: 'navy'
        },
        phone: {
            cursor: 'pointer',
            color: hoveringOverPhone ? Colors.primary1100 : 'black',
            textDecoration: 'none'
        },
        email: {
            cursor: 'pointer',
            color: hoveringEmail ? Colors.primary1100 : 'black',
            textDecoration: 'none'
        },
        headerLogoContainer: {
            justifyContent: windowSize[0] > 1000 ? 'flex-end' : 'center', 
            width: windowSize[0] > 1000 ? '20%' : '72%'
        },
        vibrantText: {
            color: 'black', 
            fontSize: 30,
            fontWeight: 'bold',
            color: Colors.primary1100
        },
    }

    return (
        <>
        <div className='header' style={{position: 'relative'}}>
        <IconButton style={{marginLeft: 15}} onClick={() => setDrawerIsVisible(true)}>
            <MenuIcon style={{color: 'navy'}}/>
        </IconButton>
        <div style={styles.headerLogoContainer} className='headerLogoContainer'>
            <img src={require('../../assets/breddilogo.png')} style={{width: 175}}/>
        </div>
        <div className='toolbar'>
            {windowSize[0] > 1000 && 
                <div className='toolbarButtonsContainer'>
                    <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(true);
                                setNavigate('/')
                            }, 650)
                        }} color='navy' >Home</EmptyButton>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/about'), 650)
                            
                        }} color='navy' >About</EmptyButton>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/past-transactions'), 650)
                            
                        }} color='navy' >Past Transactions</EmptyButton>      
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/home-services'), 650)
                            
                        }} color='navy' >Home Services</EmptyButton>                     
                        <EmptyButton color='navy'  onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/listings'), 650)
                            
                        }}>LISTINGS</EmptyButton>
                </div>
            }
        </div>
    </div>
        <div style={{display: 'flex', alignItems: windowSize[0] < 800 ? 'flex-start' : 'center', justifyContent: 'center', width: '100vw', height: '85vh'}}>
            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{display: 'flex', flexDirection: windowSize[0] < 800 ? 'column' : 'row', margin: 25, alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{width: windowSize[0] < 800 ? '100%' : '55%', marginBottom: windowSize[0] < 800 && 25, marginRight: windowSize[0] > 800 && 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <h2 style={styles.vibrantText}>Contact Information</h2>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <div style={{marginBottom: 10, fontSize: 20}}>
                                <span style={{fontWeight: 'bold'}}>Phone Number:</span><a href='tel:9134817786' style={styles.phone} onMouseEnter={() => setHoveringOverPhone(true)} onMouseLeave={() => setHoveringOverPhone(false)}> (913) 481-7786</a>
                            </div>
                            <div style={{fontSize: 20}}>
                                <span style={{fontWeight: 'bold'}}>Email:</span><a href='https://mail.google.com/mail/?view=cm&fs=1&to=bharthi@sbcglobal.net' style={styles.email} onMouseEnter={() => setHoveringEmail(true)} onMouseLeave={() => setHoveringEmail(false)}> bharthi@sbcglobal.net</a>
                            </div>
                        </div>

                    </div>
                    <img src={require('../../assets/breddy.png')} style={{width: 200, borderRadius: 8}}/>
                </div>
            </div>
        </div>
        <DrawerComponent visible={drawerIsVisible} onCloseDrawer={() => setDrawerIsVisible(false)} activeItem="contact"/>
        <LoadingOverlay visible={loading}/>
        </>
    )
};