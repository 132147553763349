import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import { Colors } from "../constants/Colors";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button";
import FilledButton from "./UI/FilledButton";
import { useState } from "react";
import emailjs from '@emailjs/browser';
import LoadingOverlay from "./LoadingOverlay";
import { useEffect } from "react";

export default function FormModal({ visible, onClose, onSubmit }) {
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [phoneNum, setPhoneNum] = useState('');
const [additionalInfo, setAdditionalInfo] = useState('');
const [loading, setLoading] = useState(false);
const [emailValidated, setemailValidated] = useState(false);
const [phoneNumValidated, setPhoneNumValidated] = useState(false);
const [submitCalled, setSubmitCalled] = useState(false);
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const [windowSize, setWindowSize] = useState([
  window.innerWidth,
  window.innerHeight,
]);

(function(){
  emailjs.init("ysATXexeL_v55a3Y3");
})();

useEffect(() => {
  const handleWindowResize = () => {
    setWindowSize([window.innerWidth, window.innerHeight]);
  };

  window.addEventListener('resize', handleWindowResize);

  return () => {
    window.removeEventListener('resize', handleWindowResize);
  };
}, []);

  const modal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: windowSize[0] < 800 ? 274 : 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 8,
  };

  function sendMail() {
    setSubmitCalled(true);
    if (re.test(email)) {
      console.log("email is true")
      setemailValidated(true)
    } else {
      setemailValidated(false);
    }
    if (phoneNum.length === 10) {
      console.log("PHONE NUM IS TRUE")
      setPhoneNumValidated(true);
    } else {
      setPhoneNumValidated(false);
    }
  
    console.log(emailValidated);
    console.log(phoneNumValidated);
    if (emailValidated && phoneNumValidated) {
      console.log("validated");
        const contactParams = {
          from_name: name,
          from_email: email,
          from_phonenumber: phoneNum,
          from_additionalinfo: additionalInfo
        };
    
    
        // emailjs.send('service_s1nukfr', 'template_2ptozbj', contactParams).then(function (res) {})
        onSubmit();
    }
  }

  return (
    <>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={visible}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={visible}>
        <Box sx={modal}>
          <Typography
            id="transition-modal-title"
            variant="h5"
            component="h1"
            style={{ textAlign: "center" }}
          >
            Enter Contact Information
          </Typography>
          <Typography
            id="transition-modal-title"
            variant="h7"
            component="h4"
            style={{ textAlign: "center", color: Colors.primary1100 }}
          >
            Bharthi Reddi will get in touch
          </Typography>
          <br />
          <div style={{ textAlign: "center" }}>
            <TextField id="standard-basic" label="Name" variant="standard" fullWidth={300} inputProps={{style: {paddingBottom: 10}}} onChange={val => setName(val.target.value)}/>
          </div>
          <br />
          <div style={{ textAlign: "center" }}>
            <TextField id="standard-basic" error={(!emailValidated && submitCalled) && true} label="E-mail" variant="standard" fullWidth={300} inputProps={{style: {paddingBottom: 10}}} onChange={val => {
              setEmail(val.target.value);
              if (!emailValidated) {
                if (re.test(val.target.value)) {
                  setemailValidated(true)
                } else {
                  setemailValidated(false);
                }
              }
            }}/>
            {(!emailValidated && submitCalled) && <p style={{color: 'red', textAlign: 'left', margin: 0, fontWeight: '500'}}>Invalid Email</p>}
          </div>
          <br />
          <div style={{ textAlign: "center" }}>
            <TextField
              id="standard-basic"
              label="Phone Number"
              variant="standard" 
              fullWidth={300}
              inputProps={{style: {paddingBottom: 10}}}
              onChange={val => {
                setPhoneNum(val.target.value)
                if (phoneNum.length === 9) {
                  setPhoneNumValidated(true);
                }
              }}
              error={(!phoneNumValidated && submitCalled) && true}
            />
            {(!phoneNumValidated && submitCalled) && <p style={{color: 'red', textAlign: 'left', margin: 0, fontWeight: '500'}}>Invalid Phone Number</p>}
          </div>
          <br />
          <div style={{ textAlign: "center" }}>
            <TextField
              id="outlined-multiline-static"
              label="Additional Information (optional)"
              multiline
              rows={4}
              fullWidth={300}
              onChange={val => setAdditionalInfo(val.target.value)}
            />{" "}
          </div>
          <div style={{flexDirection: 'row', display: 'flex', margin: 8, justifyContent: 'space-between', alignItems: 'center', marginTop: 23}}>
            <Button onClick={onClose} style={{color: Colors.accent300, fontSize: 16, height: 35, borderRadius: 6}}>Cancel</Button>
            <FilledButton onClick={sendMail}>Submit</FilledButton>
          </div>
        </Box>
      </Fade>
    </Modal>

  <LoadingOverlay visible={loading}/>
  </>
  );
}
