import { Colors } from '../../constants/Colors';
import MenuIcon from '@mui/icons-material/Menu';
import { Navigate, useNavigate } from 'react-router-dom';
import FilledButton from '../../componenets/UI/FilledButton';
import bckImage from '../../assets/firstimage.jpg';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import OutlinedButton from '../../componenets/UI/OutlinedButton';
import EmptyButton from '../../componenets/UI/EmptyButton';
import { IconButton } from '@mui/material';
import FormModal from '../../componenets/FormModal';
import DrawerComponent from '../../componenets/Drawer';
import LoadingOverlay from '../../componenets/LoadingOverlay';
import { useState, useRef, useEffect } from 'react'
import 'swiper/css/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import AlertModal from '../../componenets/AlertModal';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './styles.css';


const delay = 4000;
const items = [
    {
        name: 'Sriram Kalyanasundaram',
        avatarPath: '../../assets/profile.jpg',
        body: 'We dove into the home buying process pretty quickly, and Bharathi was with us every step of the way. She made last-minute showings happen, and we snatched up our first home quickly with her help. We didn’t think it would be possible to do this so soon, but Bharathi was a great resource to show us the light and do some first-time home buyer handholding. She made herself available for showing inspite of her busy schedules. Overall we would rate Bharathi 5/5',
        key: 0,
        rating:                 <Rating name='read-only' value={5} readOnly style={{marginTop: 2}}/>

    },
    {
        name: 'Sudheer Varma',
        avatarPath: '../../assets/profile.jpg',
        body: `We decided to buy a house. While discussing friends, I got to know about Bharthi Reddi. I have contacted her and explained my scenario. She heard my requirements with tons of patience. She also gave us the information on what to look for as first time home buyers. That helped us a lot. We started our house hunt with her, learned so many things. She is more informative; explains pros and cons of the listing. Finally, we found our dream home. I'm really impressed with her job in this process. Definitely, I will refer her to my friends and colleagues. I appreciate Bharthi for all her help in this whole process!`,
        key: 1,
        rating: <Rating name='read-only' value={5} readOnly style={{marginTop: 2}}/>

    },
    {
        name: 'Nara Santhanakrishnan',
        rating: <Rating name='read-only' value={5} readOnly/>,
        avatarPath: '../../assets/avatar.png',
        body: `
        We wanted to take a moment to express our deepest gratitude for your exceptional dedication and expertise in helping us find our dream home. Your hard work, patience,
        and commitment truly made a world of difference in our home-buying journey, and we couldn't be happier with the outcome.
       From the very beginning, you took the time to understand our preferences, needs, and budget, and location which allowed you to present us with endless properties that perfectly matched our criteria.
        Your knowledge of the market, attention to detail, and ability to anticipate our requirements ensured that the homes you showed us were always worth considering.
       Your guidance throughout the process, from initial viewings to negotiations and the final closing, was invaluable. Your expertise put us at ease, especially during moments when decisions felt overwhelming.
        Your professionalism, transparency, and responsiveness reassured us that we were in capable hands, making this experience smooth and enjoyable.
       We are incredibly grateful for your unwavering support, your willingness to answer our endless questions, and your genuine care for our satisfaction. Thanks to you, we've found more than just a house;
        we've found a place where we can create lasting memories and build our future.
       Please accept our heartfelt thanks for going above and beyond to make this a reality for us. You have not only found us a house, but you have also helped us find a home where our dreams can flourish.
        We will always speak highly of your services and will enthusiastically recommend you to anyone seeking a real estate professional.`,
        key: 2
    },
    {
        name: 'Leslie and Mike',
        avatarPath: '../../assets/profile.jpg',
        body: `Hello Bharthi! We want to thank you for bringing your clients to us in Stonebridge Pointe. We'd also like to express over many thanks for your enthusiastic representation of your clients. It is both refreshing and rewarding to work with you. Highest regards, Leslie and Mike.`,
        key: 3,
        rating: <Rating name='read-only' value={5} readOnly style={{marginTop: 2}}/>

    },
]

export default function HomeScreen() {
    const [navigate, setNavigate] = useState('');
    const [index, setIndex] = useState(0);
    const [modalIsVisible, setModalIsVisible] = useState(false)
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);
    const timeoutRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [cardClicked, setCardClicked] = useState(false);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    const [alertIsVisible, setAlertIsVisible] = useState(false);
    const [name, setName] = useState('');

      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    function resetTimeout() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === 4 ? 0 : prevIndex + 1
            ),
          delay
        );
        return () => {
            resetTimeout();
        };

      }, [index]);



    if (navigate !== '') {
        return <Navigate to={navigate} replace={true}/>
    }

    function openAlertModalHandler() {
        setModalIsVisible(false);
        setAlertIsVisible(true);
    }

    const styles = {
        contentContainer1: {
            flexDirection: windowSize[0] < 800 ? 'column' : 'row',
        },
        contentContainer2: {
            width: windowSize[0] > 1200 ? '25%' : '100%',
        },
        contentContainer3: {
            opacity: cardClicked && 0.4,
            marginTop: (windowSize[0] < 1483 && windowSize[0] > 1200) ? 100 : 25,
            flexDirection: windowSize[0] > 1200 ? 'row' : 'column',
            width: windowSize[0] > 1200 ? '45%' : '60%',
            height: windowSize[0] > 1200 && 350,
            marginBottom: windowSize[0] < 1200 && 25,
            marginLeft: windowSize[0] > 1200 && 35,
            marginRight: windowSize[0] > 1200 && 50
        },
        contentContainer5: {
            marginLeft: windowSize[0] > 1200 ? 50 : (windowSize[0] < 1200 && windowSize[0] > 800) && 30,
            marginRight: windowSize[0] > 1200 && 50,
            marginBottom: windowSize[0] > 1200 ? 50 : 25,
            width: windowSize[0] < 800 ? '80%' : (windowSize[0] < 1200 && windowSize[0] > 800) ? '45%' : '76%',
            alignItems: windowSize[0] > 1200 ? 'flex-start' : 'center',
        },
        contentContainer6and7: {
            margin: windowSize[0] > 1200 && 30,
            marginLeft: (windowSize[0] > 800 && windowSize[0] < 1200) && 25,
            width: windowSize[0] > 1200 ? '60%' : '80%',
            overflow: windowSize[0] > 1200 && 'hidden',
        },
        contentRow1: {
            marginBottom: windowSize[0] < 1200 && 25,
        },
        contentRow2: {
            flexDirection: windowSize[0] > 1200 ? 'row' : 'column', 
            alignItems: windowSize[0] < 1200 && 'center',
            padding: windowSize[0] < 800 && 15  ,   
            paddingTop: (windowSize[0] > 800 && windowSize[0] < 1200) && 30
        },
        quoteContainer: {
            justifyContent: windowSize[0] < 1200 && 'center'
        },
        headerLogoContainer: {
            justifyContent: windowSize[0] > 1000 ? 'flex-end' : 'center', 
            width: windowSize[0] > 1000 ? '20%' : '100%',
            marginRight: windowSize[0] < 1200 && 45
        },
        marketStatButtonContainer: {
            width: windowSize[0] < 1640 ? '80%' : '35%', 
            textAlign: 'center'
        },
        marketStatTitle: {
            textAlign: 'center', 
            color: Colors.primary1400
        },
        logo: {
            height: 200, 
            marginTop: windowSize[0] > 1200 ? 25 : -35, 
            marginRight: (windowSize[0] > 800 && windowSize[0] < 1200) && 60
        },
        innerTipsContainer: {
            display: 'flex', 
            flexDirection: windowSize[0] < 800 ? 'column' : 'row', 
            justifyContent: windowSize[0] < 1200 && 'center', 
            alignItems: windowSize[0] < 1200 && 'center'
        }
        
}

    return (
        <>
        <div style={{height: windowSize[0] > 1200 ? '50vh' : '37vh', backgroundImage: `url(${bckImage})`}} className='backgroundImage'>
            <div className='header'>
                <IconButton style={{marginLeft: 15}} onClick={() => setDrawerIsVisible(true)}>
                    <MenuIcon style={{color: 'navy'}}/>
                </IconButton>
                <div style={styles.headerLogoContainer} className='headerLogoContainer'>
                    <img src={require('../../assets/breddilogo.png')} style={{width: 175}}/>
                </div>
                <div className='toolbar'> {windowSize[0] > 1000 && 
                    <div className='toolbarButtonsContainer'>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(true);
                                setNavigate('/about')
                            }, 650)
                        }} color='navy' >About</EmptyButton>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/contact'), 650)
                            
                        }} color='navy' >Contact</EmptyButton>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/past-transactions'), 650)
                            
                        }} color='navy' >Past Transactions</EmptyButton>      
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/home-services'), 650)
                            
                        }} color='navy' >Home Services</EmptyButton>                     
                        <EmptyButton color='navy'  onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/listings'), 650)
                            
                        }}>LISTINGS</EmptyButton>
                    </div>
                    }
                </div>
            </div>
        </div>
        <div style={{overflow: 'hidden'}}>
            <div style={styles.contentRow1} className='contentRow1'>
                    <div style={styles.contentContainer1} className='contentContainer1'>
                        <img src={require('../../assets/breddilogo.png')} style={styles.logo}/>
                        <div className='buyAndSellContainer'>
                            <div style={styles.quoteContainer} className='quoteContainer'>
                                <img src={require('../../assets/quote.png')} style={{height: 175, width: 285, borderRadius: 50}}/>
                            </div>
                            <div className='buySellButtonsContainer'>
                                <FilledButton color={Colors.primary1100} onClick={() => setModalIsVisible(true)}>{windowSize[0] < 1895 ? 'BUY' : 'BUY A HOME'}</FilledButton>
                                <FilledButton color={Colors.primary1100} onClick={() => setModalIsVisible(true)}>{windowSize[0] < 1895 ? 'SELL' : 'SELL A HOME'}</FilledButton>
                            </div>
                        </div>
                        {windowSize[0] > 1375 && <img src={require('../../assets/house2.jpg')} className='roundedHouse'/>}
                    </div>
                    <div style={styles.contentContainer2} className='contentContainer2'>
                        <div className='innerContentContainer2'>
                            <h2 style={styles.marketStatTitle}>Check out Kansas City's <span className='vibrantText'>market statistics!</span></h2>
                            <div style={styles.marketStatButtonContainer}>
                                <FilledButton color={Colors.primary1100} onClick={() => window.open('https://kcrar.com/media-statistics/market-statistics/')}>CLICK HERE</FilledButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.contentRow2} className='contentRow2'>
                    {windowSize[0] > 1561 && <div style={styles.contentContainer3} className='contentContainer3' onClick={ () => { windowSize[0] < 1200 && setCardClicked(true); }}>
                        <div className='paragraphContainer'>
                             <p className='paragraphTitle'>Why you should buy a home in <span className='vibrantText'>Kansas City</span></p>
                             <p className='paragraph'>Buying a home in Kansas City presents a compelling opportunity for prospective homeowners. With its vibrant culture, affordable housing options, and a thriving job market, Kansas City offers an appealing blend of urban amenities and a friendly, Midwestern atmosphere. The city boasts diverse neighborhoods, each with its own unique charm and character, providing homebuyers with a range of choices to suit their preferences and needs. Whether it's the historic charm of Westport, the artistic ambiance of the Crossroads Arts District, or the family-friendly suburbs of Overland Park, there is a perfect neighborhood for everyone. Moreover, the cost of living in Kansas City is relatively affordable, making homeownership within reach for many. The city's booming economy, supported by major industries like healthcare, technology, and finance, provides residents with ample job opportunities and a stable economic outlook. <span style={styles.vibrantText} className='vibrantText'>Contact Bharthi Reddi and buy a home now in Kansas City!</span></p>
                        </div>
                    </div>}
                    <div className='tipsContainer'>
                        <div style={styles.innerTipsContainer}>
                            <div style={styles.contentContainer6and7} className='contentContainer6and7'>
                                <h2 style={{color: Colors.primary1400, paddingLeft: 15}}>Tips on <span style={styles.vibrantText} className='vibrantText'>buying</span></h2>
                                <ul >
                                    <li className='bulletPoint'>Determine your budget</li>
                                    <li className='bulletPoint'>Get pre-approved for a mortgage</li>
                                    <li className='bulletPoint'>Research the market</li>
                                    <li className='bulletPoint'>Conduct thorough inspections</li>
                                    <li className='bulletPoint'>Consider future resale value</li>
                                </ul>
                            </div>
                            <div style={styles.contentContainer6and7} className='contentContainer6and7'>
                            <h2 style={{color: Colors.primary1400, paddingLeft: 15}}>Tips on <span style={styles.vibrantText} className='vibrantText'>selling</span></h2>
                                <ul >
                                    <li className='bulletPoint'>Set the right price</li>
                                    <li className='bulletPoint'>Enhance curb appeal</li>
                                    <li className='bulletPoint'>Stage the interior</li>
                                    <li className='bulletPoint'>Market effectively</li>
                                    <li className='bulletPoint'>Prepare for negotiations and inspections</li>
                                </ul>
                            </div>
                        </div>
                        <div style={styles.contentContainer5} className='contentContainer5'>
                            { windowSize[0] < 1200 ? <div className='websiteContainer'>
                                <h2 style={{textAlign: 'center'}}>Looking to buy a house?</h2>
                                <div className='websiteRow' style={{display: 'flex'}}>
                                    <div style={{ marginLeft: 10}}>
                                        <OutlinedButton onClick={() => window.open('https://www.zillow.com/homes/')}>Zillow </OutlinedButton>
                                    </div>
                                    <div style={{marginTop: 3}}>
                                        <OutlinedButton onClick={() => window.open('https://www.trulia.com/County/KS/Johnson_Real_Estate/')}>TRULIA </OutlinedButton>
                                    </div>
                                </div>
                                <div className='websiteRow' style={{display: 'flex'}}>
                                    <div style={{marginTop: 3, marginLeft: 10}}>
                                    <OutlinedButton onClick={() => window.open('https://www.redfin.com/houses-near-me')}>REDFIN</OutlinedButton>
                                        </div>
                                        <div style={{marginTop: 3}}>
                                        <OutlinedButton onClick={() => window.open('https://www.movoto.com/for-sale/')}>MOVOTO</OutlinedButton>
                                        </div>
                                    </div>
                                    <div className='websiteRow' style={{display: 'flex'}}>
                                        <div style={{marginTop: 3}}>
                                            <OutlinedButton onClick={() => window.open('https://www.homes.com/')}>HOMES</OutlinedButton>
                                        </div>
                                    </div>
                                </div> : 
                                <>
                                <div style={{flexDirection: (windowSize[0] < 1200 && windowSize[0] > 800) && 'row'}}>
                                    <h2 style={{textAlign: 'center'}}>Looking to buy a house?</h2>
                                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                                        <div style={{marginTop: 3, marginLeft: 10}}>
                                            <OutlinedButton onClick={() => window.open('https://www.zillow.com/homes/')}>Zillow</OutlinedButton>
                                        </div>
                                        <div style={{marginTop: 3}}>
                                            <OutlinedButton onClick={() => window.open('https://www.trulia.com/County/KS/Johnson_Real_Estate/')}>TRULIA</OutlinedButton>
                                        </div>
                                        <div style={{marginTop: 3}}>
                                            <OutlinedButton onClick={() => window.open('https://www.redfin.com/houses-near-me')}>REDFIN</OutlinedButton>
                                        </div>
                                        <div style={{marginTop: 3}}>
                                            <OutlinedButton onClick={() => window.open('https://www.movoto.com/for-sale/')}>MOVOTO</OutlinedButton>
                                        </div>
                                        <div style={{marginTop: 3}}>
                                            <OutlinedButton onClick={() => window.open('https://www.homes.com/')}>HOMES</OutlinedButton>
                                        </div>
                                    </div>
                                </div>
                                </>}
                            </div>
                            </div>
                        </div>
                    </div>

            <div style={{backgroundColor: '#cccccc', padding: 50}}>
            <h3 style={{color: Colors.primary1400, textAlign: 'center', fontSize: 25}}>REVIEWS</h3>
            {windowSize[0] > 1050 && <Swiper
        navigation={true}
        pagination={{
          clickable: true
        }}
        centeredSlides={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        cssMode={true}
      >{
        items.map((val) => {
            return <SwiperSlide>
                <div className='innerSwiper'>
                    <div className='swiperHeaderContainer'>
                        <Avatar src={require('../../assets/profile.jpg')} />
                        <p className='swiperName'>{val.name}</p>
                        {val.rating}
                    </div>
                    <p className='swiperBody' style={{fontSize: val.name === "Nara Santhanakrishnan" && 10}}>{val.body}</p>
                </div>
            </SwiperSlide>
        })
    }
      </Swiper>}
    <div className='reviewContainer'>
      {windowSize[0] < 1050 && items.map((obj) => {
                return (
                    <div className='reviewCard'>
                        <div className='innerReview'>
                            <div className='reviewHeading'>
                                <p className='reviewName'>{obj.name}</p>
                                <div className='ratingContainer'>
                                    {obj.rating}
                                </div>
                            </div>
                            <p >
                                {obj.body}
                            </p>
                        </div>
                    </div>
                );
            })
        }
    </div>

            </div>

            

        <FormModal visible={modalIsVisible} onClose={() => setModalIsVisible(false)} onSubmit={openAlertModalHandler}/>

        <DrawerComponent visible={drawerIsVisible} onCloseDrawer={() => setDrawerIsVisible(false)} activeItem="home"/>

        <LoadingOverlay visible={loading}/>

        <AlertModal visible={alertIsVisible} onClose={() => setAlertIsVisible(false)} message="Bharthi Reddi has been contacted and will reach out to you soon!" />
        </> 
    )
};