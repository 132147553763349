import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import EmptyButton from '../../componenets/UI/EmptyButton';
import { useState, useEffect } from 'react';
import DrawerComponent from '../../componenets/Drawer';
import './styles.css'
import OutlinedButton from '../../componenets/UI/OutlinedButton';
import { Colors } from '../../constants/Colors';
import { Navigate, useNavigate } from 'react-router-dom';
import LoadingOverlay from '../../componenets/LoadingOverlay';
export default function ListingsScreen() {
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    const [loading, setLoading] = useState(false);
    const [flip1, setFlip1] = useState(false);
    const [flip2, setFlip2] = useState(false);
    const [nav, setNavigate] = useState("");
    const navigate = useNavigate();
    const [listingDetail, setListingDetail] = useState(null);

      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);
      
    const styles = {
        header: {
            flexDirection: 'row', 
            display: 'flex', 
            flex: 1,
            boxShadow: "0px 5px 10px rgb(0 0 0 / 0.2)",
            backgroundColor: 'lightgray',
            opacity: 0.7
        },
        toolbar: {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            flex: 1
        },
        toolbarTitle: {
            textAlign: 'center', 
            color: 'navy'
        },
        toolbarButtonsContainer: {
            marginLeft: 50, 
            marginTop: 5
        },
        toolbarButton: {
            marginRight: 15, 
            fontSize: 20,
            color: 'navy'
        },
        vibrantText: {
            color: 'black', 
            fontSize: 30,
            fontWeight: 'bold',
            color: Colors.primary1100
        },
    }

    if (nav !== "") {
        return <Navigate to={nav}/>
    }

    if (listingDetail) {
        navigate('/listing-detail', {state: { data: listingDetail }});
    }

    return (
    <>
    <div style={styles.header}>
        <IconButton style={{marginLeft: 15}} onClick={() => setDrawerIsVisible(true)}>
            <MenuIcon style={{color: 'navy'}}/>
        </IconButton>
        <div style={{display: 'flex', justifyContent: windowSize[0] > 1000 ? 'flex-end' : 'center', width: windowSize[0] > 1000 ? '20%' : '75%'}}>
            <img src={require('../../assets/breddilogo.png')} style={{width: 175}}/>
        </div>
        <div style={styles.toolbar}>
            {windowSize[0] > 1000 && <div style={styles.toolbarButtonsContainer}>
            <EmptyButton color='navy'  onClick={() => {
                setLoading(true);
                setTimeout(() => setNavigate('/'), 650)
                
            }}>Home</EmptyButton>
            <EmptyButton onClick={() => {
                setLoading(true);
                setTimeout(() => {
                    setLoading(true);
                    setNavigate('/about')
                }, 650)
            }} color='navy' >About</EmptyButton>
            <EmptyButton onClick={() => {
                setLoading(true);
                setTimeout(() => setNavigate('/contact'), 650)
                
            }} color='navy' >Contact</EmptyButton>
            <EmptyButton onClick={() => {
                setLoading(true);
                setTimeout(() => setNavigate('/past-transactions'), 650)
                
            }} color='navy' >Past Transactions</EmptyButton>      
            <EmptyButton onClick={() => {
                setLoading(true);
                setTimeout(() => setNavigate('/home-services'), 650)
                
            }} color='navy' >Home Services</EmptyButton>                     
            </div>}
        </div>
    </div>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100vw'}}>
                <p style={styles.vibrantText}>Current Listings</p>
            </div>
    <div style={{flexWrap: 'wrap', display: 'flex', alignItems: 'center', justifyContent: 'center', width: windowSize[0] > 800 && '92vw'}}>
        {/* <div onMouseEnter={() => setFlip1(true)} onMouseLeave={() => setFlip1(false)} onClick={() => {
            if (windowSize[0] < 1000) {
                setFlip1(!flip1);
                setFlip2(false)
            }
        }} style={{heigth: 350, width: windowSize[0] > 1000 && 450, backgroundColor: 'transparent', borderRadius: 20}} >
    <div className={`card ${flip1 ? "flip" : ""}`} style={{ borderRadius: 25 ,backgroundColor: '#f1f1f1', height: 325, width: windowSize[0] < 1000 ? 325 : 425, display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, marginLeft: windowSize[0] < 1000 && 20, margin: windowSize[0] > 1000 && 50}} >
        {!flip1 ? 
        <>
            <div style={{alignItems: 'center', justifyContent: 'center', flex: 1, height: 325, width: 325, borderRadius: 25, display: 'flex',  backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: 'url(https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2436272/org/000.jpg?v=2023-07-04_114813&width=400)'}}>
                <h3 style={{color: 'white', fontSize: 25}}>3518 Paseo Boulevard</h3>
            </div>
        </> : flip1 &&
        <div className='back'>
            <div style={{flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{color: 'black', fontWeight: 'bold', textAlign: 'center', fontSize: 22, margin: 20}}>3518 Paseo Boulevard, Kansas City, MO 64109</p>
                <p style={{color: 'gray', fontWeight: 'bold'}}>4 BED 5 BATH</p>
                <p style={{color: 'gray', fontWeight: 'bold'}}>5,662 sq. ft.</p>
                <OutlinedButton onClick={() => {
                    setLoading(true);
                    setTimeout(() => setListingDetail({ 
                        img: 'https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2436272/org/000.jpg?v=2023-07-04_114813&width=400', 
                        address: '3518 Paseo Boulevard, Kansas City, MO 6410', 
                        bedbath: '4 BED 5 BATH', 
                        sqft: '3,139', 
                        street: '3518 PASEO BOULEVARD', 
                        cityANDzipcode: "KANSAS CITY, MO 6410", 
                        garage: '1 car garage',
                        builddate: 'Built in 1911',
                        amenities: 'Has porch',
                        HOA: 'Has HOA',
                        parcelNumber: '301000511000000000',
                        basement: 'unfinished',
                        price: '$280,000',
                        elementary: 'Faxon Elementary School',
                        middleschool: 'Central Middle School',
                        highschool: 'Central High School',
                        schooldistrict: 'Kansas City Public Schools',
                        overview: `This Beautiful 3+ Story Building is located on Popular St of PASEO BLVD. The seller is motivated to sell the property—buyer or Buyer's Agent to verify - square footage - room sizes & taxes. Bring your designer and New Ideas; to make this house -your dream home! There is a lot to love about this home. This house is close to restaurants, shops, and HWay.` 
                    }), 650)
                }}>More Info</OutlinedButton>
            </div>
        </div>
            }
        </div>
        </div> */}


        <div onMouseEnter={() => setFlip2(true)} onMouseLeave={() => setFlip2(false)} onClick={() => {
            if (windowSize[0] < 1000) {
                setFlip2(!flip2);
                setFlip1(false)
            }
        }} style={{heigth: 350, width: windowSize[0] > 1000 && 450, backgroundColor: 'transparent', borderRadius: 20}} >
    <div className={`card ${flip2 ? "flip" : ""}`} style={{ borderRadius: 25 ,backgroundColor: '#f1f1f1', height: 325, width: windowSize[0] < 1000 ? 325 : 425, display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, marginLeft: windowSize[0] < 1000 && 20, margin: windowSize[0] > 1000 && 50}} >
        {!flip2 ? 
        <>
            <div style={{alignItems: 'center', justifyContent: 'center', flex: 1, height: 325, width: 325, borderRadius: 25, display: 'flex',  backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: 'url(https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2442103/org/000.jpg?v=2023-07-19_153344&width=400)'}}>
                <h3 style={{color: 'black', fontSize: 25}}>13831 Madison Avenue</h3>
            </div>
        </> : flip2 &&
        <div className='back'>
            <div style={{flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{color: 'black', fontWeight: 'bold', textAlign: 'center', fontSize: 22, margin: 20}}>13831 Madison Avenue, Kansas City, MO 64145</p>
                <p style={{color: 'gray', fontWeight: 'bold'}}>0.5 Acres</p>
                <OutlinedButton onClick={() => {
                    setLoading(true);
                    setTimeout(() => setListingDetail({ 
                        img: 'https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2442103/org/000.jpg?v=2023-07-19_153344&width=400', 
                        address: '13831 Madison Avenue, Kansas City, MO 64145', 
                        bedbath: 'LAND ONLY', 
                        sqft: '0.5 Acres | 21780', 
                        street: '13831 Madison Avenue', 
                        cityANDzipcode: " Kansas City, MO 64145", 
                        garage: 'N/A',
                        builddate: 'Build Date: N/A',
                        amenities: 'N/A',
                        HOA: 'N/A',
                        parcelNumber: '301000511000000000',
                        basement: 'N/A',
                        price: '$350,000',
                        elementary: 'Martin City Elementary School',
                        middleschool: '',
                        highschool: 'Grandview Sr. High School',
                        schooldistrict: 'Grandview C-4 School District',
                        overview: `Sell only Land. The location is very good near Hway 150. Commercial properties surround the Land. Very Good opportunity to open a Coffee Shop, Retail Store, or any Store. It is Going to be a very Profitable Business. Do not miss this Great opportunity.` 
                    }), 650)
                }}>More Info</OutlinedButton>
            </div>
        </div>
            }
        </div>
        </div>


    </div>



    <DrawerComponent visible={drawerIsVisible} onCloseDrawer={() => setDrawerIsVisible(false)} activeItem="cl"/>
    <LoadingOverlay visible={loading}/>
    </>
    )
};