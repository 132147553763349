import { useState, useEffect } from 'react';
import './styles.css'
import { IconButton } from '@mui/material';
import DrawerComponent from '../../componenets/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import EmptyButton from '../../componenets/UI/EmptyButton';
import LoadingOverlay from '../../componenets/LoadingOverlay';
import { Navigate } from 'react-router-dom';
import { Colors } from '../../constants/Colors';
export default function PastTransactionsScreen() {
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    const [navigate, setNavigate] = useState('');
    const [loading, setLoading] = useState(false);
    const [updatedHouses, setUpdatedHouses] = useState([]);
    const houses = [
        {
            street: "13720 BRADSHAW ST",
            cityzip: "OVERLAND PARK, KS, 66221",
            sqft: "3,139 sq. ft.",
            bedbath: "4 BED | 5 BATH",
            img: "https://photos.zillowstatic.com/fp/d747a7e9b945377e41691ed6170806e4-cc_ft_768.webp",
            price: "$339,900",
            id: 0
        },
        {
            street: "15604 VALLEY VIEW DR",
            cityzip: "OVERLAND PARK, KS, 66223",
            sqft: "2,464 sq. ft.",
            bedbath: "4 BED | 5 BATH",
            img: "https://ssl.cdn-redfin.com/photo/157/bigphoto/554/2092554_3.jpg",
            price: "$330,000",
            id: 1
        },
        {
            street: "12710 W 160TH TERRACE",
            cityzip: "OVERLAND PARK, KS, 66221",
            sqft: "4,312 sq. ft.",
            bedbath: "4 BED | 6 BATH",
            img: "https://www.trulia.com/pictures/thumbs_4/zillowstatic/fp/c1ae116e158b877c495df347b91e6896-full.jpg",
            price: "$924,999",
            id: 2
        },
        {
            street: "13821 HASKINS ST",
            cityzip: "OVERLAND PARK, KS, 66221",
            sqft: "3,826 sq. ft.",
            bedbath: "4 BED | 5 BATH",
            img: "https://photos.zillowstatic.com/fp/6f5e81989588a3d0cd97bf0fd8692d93-cc_ft_768.webp",
            price: "$369,950",
            id: 2
        },
        {
            street: "12301 FAIRWAY ROAD",
            cityzip: "LEAWOOD, KS, 66209",
            sqft: "4,146 sq. ft.",
            bedbath: "4 BED | 5 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2328768/org/000.jpg?v=2022-12-30_140859&width=1500",
            price: "$519,999",
            id: 3
        },
        {
            street: "14102 PARKHILL ST",
            cityzip: "OVERLAND PARK, KS, 66221",
            sqft: "3,139",
            bedbath: "4 BED | 5 BATH",
            img: "https://photos.zillowstatic.com/fp/946f3318e64c803ca99cbb8ce1fd3644-uncropped_scaled_within_1536_1152.webp",
            price: "$485,000",
            id: 4
        },
        {
            street: "7825 W 147TH TERRACE",
            cityzip: "OVERLAND PARK, KS, 66223",
            sqft: "2,346 sq. ft.",
            bedbath: "4 BED | 4 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2330430/org/000.jpg?v=2022-12-30_140859&width=400",
            price: "$460,000",
            id: 5
        },
        {
            street: "15663 S WIDMER ST",
            cityzip: "OLATHE, KS, 66062",
            sqft: "3,097 sq. ft.",
            bedbath: "4 BED | 4 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2377567/org/000.jpg?v=2022-12-30_141049&width=400",
            price: "$475,000",
            id: 6
        },
        {
            street: "1520 CROSS CREEK DR",
            cityzip: "RAYMORE, MO, 64083",
            sqft: "3,762 sq. ft.",
            bedbath: "6 BED | 5 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2242762/org/000.jpg?v=2022-12-30_140711&width=400",
            price: "$459,999",
            id: 7
        },
        {
            street: "18441 W 157TH ST",
            cityzip: "OLATHE, KS, 66062",
            sqft: "3,239 sq. ft.",
            bedbath: "5 BED | 5 BATH",
            img: "https://photos.zillowstatic.com/fp/633524080ed2ea8a484efd56854078e4-uncropped_scaled_within_1536_1152.webp",
            price: "$459,000",
            id: 8
        },
        {
            street: "7645 W 148TH TERRACE",
            cityzip: "OVERLAND PARK, KS, 66223",
            sqft: "3,212 sq. ft.",
            bedbath: "4 BED | 4 BATH",
            img: "https://photos.zillowstatic.com/fp/c5f935b3086866325e2c9e1bc2e610de-uncropped_scaled_within_1536_1152.webp",
            price: "$377,576",
            id: 9
        },
        {
            street: "8714 W 132 PLACE",
            cityzip: "OVERLAND PARK, KS, 66213",
            sqft: "2,966 sq. ft.",
            bedbath: "4 BED | 3 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2341762/org/000.jpg?v=2022-12-30_140935&width=400",
            price: "$325,000",
            id: 10
        },
        {
            street: "18036 W 163RD TERRACE",
            cityzip: "OLATHE, KS, 66062",
            sqft: "2,262 sq. ft.",
            bedbath: "4 BED | 3 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2315732/org/000.jpg?v=2022-12-30_140823&width=400",
            price: "$369,000",
            id: 11
        },
        {
            street: "15313 S WIDMER ST",
            cityzip: "OLATHE, KS, 66062",
            sqft: "1,965 sq. ft.",
            bedbath: "3 BED | 2 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2350447/org/000.jpg?v=2022-12-30_140939&width=400",
            price: "$360,000",
            id: 12
        },
        {
            street: "13798 W 153RD TERRACE",
            cityzip: "OLATHE, KS, 66062",
            sqft: "2,014 sq. ft.",
            bedbath: "3 BED | 3 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2333012/org/000.jpg?v=2022-12-30_140901&width=400",
            price: "$354,999",
            id: 13
        },
        {
            street: "12110 W 48TH ST",
            cityzip: "SHAWNEE, KS, 66216",
            sqft: "3,112 sq. ft.",
            bedbath: "3 BED | 3 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2362749/org/000.jpg?v=2022-12-30_141012&width=400",
            price: "$334,999",
            id: 14
        },
        {
            street: "9617 EBY STREET",
            cityzip: "OVERLAND PARK, KS, 66212",
            sqft: "1,736 sq. ft.",
            bedbath: "3 BED | 3 BATH",
            img: "https://photos.zillowstatic.com/fp/345bf7b7978d0653dfbdd5da2563e468-cc_ft_768.webp",
            price: "$274,999",
            id: 15
        },
        {
            street: "6504 W 151 PLACE",
            cityzip: "OVERLAND PARK, KS, 66223",
            sqft: "1,460 sq. ft.",
            bedbath: "4 BED | 4 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2147750/org/000.jpg?v=2022-12-30_140414&width=400",
            price: "$269,999",
            id: 16
        },
        {
            street: "1645 W BRITTANY DRIVE",
            cityzip: "OLATHE, KS, 66061",
            sqft: "2,016 sq. ft.",
            bedbath: "3 BED | 2 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2354920/org/000.jpg?v=2022-12-30_141011&width=400",
            price: "$265,000",
            id: 17
        },
        {
            street: "15724 BARKLEY ST",
            cityzip: "OVERLAND PARK, KS, 66223",
            sqft: "1,788 sq. ft.",
            bedbath: "4 BED | 3 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2134759/org/000.jpg?v=2022-12-30_140411&width=400",
            price: "$275,000",
            id: 18
        },
        {
            street: "10903 W 59TH TERRACE",
            cityzip: "SHAWNEE, KS, 66203",
            sqft: "1,740 sq. ft.",
            bedbath: "4 BED | 2 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2321831/org/000.jpg?v=2022-12-30_140825&width=400",
            price: "$145,000",
            id: 19
        },
        {
            street: "1904 E SLEEPY HOLLOW",
            cityzip: "OLATHE, KS, 66062",
            sqft: "3,339 sq. ft.",
            bedbath: "4 BED | 2 BATH",
            img: "https://photos.zillowstatic.com/fp/0acdff6f80d3cd8198e7624b86ed3c40-cc_ft_384.webp",
            price: "$255,000",
            id: 20
        },
        {
            street: "6904 E 138TH STREET",
            cityzip: "GRANDVIEW, MO, 64030",
            sqft: "1,820 sq. ft.",
            bedbath: "4 BED | 3 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2328424/org/000.jpg?v=2022-12-30_140858&width=400",
            price: "$255,000",
            id: 21
        },
        {
            street: "14910 W 64TH st",
            cityzip: "SHAWNEE, KS, 66216",
            sqft: "1,710 sq. ft.",
            bedbath: "2 BED | 4 BATH",
            img: "https://ap.rdcpix.com/670228241/af3e2f61d3b60af130789baf524db628l-m0od-w1024_h768_x2.jpg",
            price: "$210,000",
            id: 22
        },
        {
            street: "7105 S BENTON AVE",
            cityzip: "KANSAS CITY, MO, 64132",
            sqft: "1,484 sq. ft.",
            bedbath: "3 BED | 2 BATH",
            img: "https://idx-photos-ihouseprd.b-cdn.net/KS-HMLS/2144065/org/000.jpg?v=2022-12-30_140413&width=400",
            price: "$59,999",
            id: 22
        },
    ]

    useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);
    
    const styles = {
        headerLogoContainer: {
            justifyContent: windowSize[0] > 1000 ? 'flex-end' : 'center', 
            width: windowSize[0] > 1000 ? '20%' : '75%'
        },
        vibrantText: {
            color: 'black', 
            fontSize: 30,
            fontWeight: 'bold',
            color: Colors.primary1100
        },
        rowContainer: {
            flexDirection: windowSize[0] < 800 && 'column'
        },
        card: {
            width: windowSize[0] < 800 && 333, 
            height: windowSize[0] < 800 && 395,
            marginLeft: windowSize[0] < 800 && 20,
            backgroundColor: 'white',
            borderRadius: 15,
            boxShadow: "5px 5px 10px rgb(0 0 0 / 0.2)",
            margin: 15
        },
        image: {
            borderTopLeftRadius: 15, 
            borderTopRightRadius: 15, 
            width: (windowSize[0] < 1200 && windowSize[0] > 800) ? 400 : windowSize[0] < 800 ? 333 : 400, 
            height: 250, 
            backgroundPosition: 'center', 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat'
        }
    }

    if (navigate !== '') {
        return <Navigate to={navigate}/>
    }
     
    return (
        <>
        <div className='header' style={{position: 'fixed'}}>
        <IconButton style={{marginLeft: 15}} onClick={() => setDrawerIsVisible(true)}>
            <MenuIcon style={{color: 'navy'}}/>
        </IconButton>
        <div style={styles.headerLogoContainer} className='headerLogoContainer'>
            <img src={require('../../assets/breddilogo.png')} style={{width: 175}}/>
        </div>
        <div className='toolbar'>
            {windowSize[0] > 1000 && 
                <div className='toolbarButtonsContainer'>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(true);
                                setNavigate('/')
                            }, 650)
                        }} color='navy' >Home</EmptyButton>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/about'), 650)
                            
                        }} color='navy' >About</EmptyButton>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/contact'), 650)
                            
                        }} color='navy' >Contact</EmptyButton>      
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/home-services'), 650)
                            
                        }} color='navy' >Home Services</EmptyButton>                     
                        <EmptyButton color='navy'  onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/listings'), 650)
                            
                        }}>Listings</EmptyButton>
                </div>
            }
        </div>
    </div>

        <div className='contentContainer'>
        <div style={{backgroundColor: '#f1f1f1', height: '100vh'}}>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100vw', marginTop: 100}}>
                <p style={styles.vibrantText}>Past Transactions</p>
            </div>
            <div className='rowContainer' style={styles.rowContainer}>
            {
                houses.map((obj) => {
                    console.log(houses.length + "length")
                    return (
                        <div>
                            <div  style={styles.card}>
                                <div className='innerCardContainer'>
                                    <img style={styles.image} src={obj.img} />
                                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <div>
                                            <p style={{fontSize: windowSize[0] < 800 ? 15 : 18, fontWeight: '500', margin: 15, height: windowSize[0] < 800 ? 10 : 20}}>{obj.street}</p>
                                            <p style={{fontSize: windowSize[0] < 800 ? 11 : 15, fontWeight: '500', margin: 15, height: 20}}>{obj.cityzip}</p>
                                        </div>
                                        <p style={{fontSize: 25, fontWeight: '500', color: 'green', marginLeft: windowSize[0] > 800 && 20}}>{obj.price}</p>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: windowSize[0] < 800 && 15}}>
                                        <p style={{fontWeight: '500', color: 'gray', marginLeft: 15}}>{obj.bedbath}</p>
                                        <p style={{fontWeight: '500', color: 'gray', marginLeft: 15}}>{obj.sqft}</p>
                                        <p className='sold'>SOLD</p>
                                    </div>
                                </div> 
                            </div>
                        </div>

                    )
                })
            }
        </div>
        </div>
    </div>


    <DrawerComponent visible={drawerIsVisible} activeItem="pt" onCloseDrawer={() => setDrawerIsVisible(false)}/>
    <LoadingOverlay visible={loading}/>
    </>
    )
};