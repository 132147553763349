import './App.css';
import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomeScreen from './pages/home/HomeScreen';
import AboutScreen from './pages/about/AboutScreen';
import ContactScreen from './pages/contact/ContactScreen';
import ListingsScreen from './pages/listings/ListingsScreen';
import ListingDetailScreen from './pages/listings/ListingDetailScreen';
import PastTransactionsScreen from './pages/PastTransactions/PastTransactionsScreen';
import HomeServicesScreen from './pages/homeservices/HomeServices';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<HomeScreen />}/>
        <Route path='/about' element={<AboutScreen />}/>
        <Route path='/contact' element={<ContactScreen />}/>
        <Route path='/listings' element={<ListingsScreen />}/>
        <Route path='/listing-detail' element={<ListingDetailScreen />}/>
        <Route path='/past-transactions' element={<PastTransactionsScreen />}/>
        <Route path='/home-services' element={<HomeServicesScreen />}/>
      </Routes>
    </HashRouter>
  )
}

export default App;
