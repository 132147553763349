import { Button } from '@mui/material'
import { useState } from 'react'
import { Colors } from '../../constants/Colors';

export default function FilledButton({children, onClick, error}) {
    const [hovering, setHovering] = useState(false);

    const styles = {
        button: {
            backgroundColor: !hovering ? Colors.primary1100 : Colors.primary1400,
            borderRadius: 15,
            margin: 10,
            fontWeight: 'bold',
            transition: '400ms',
            opacity: hovering && 1,
            transform: hovering && "translate(0, -7px)" 
        }
    }

    return (
        <Button onClick={onClick} variant='contained' style={styles.button} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>{children}</Button>
    )
}