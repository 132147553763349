import { Button } from '@mui/material'
import { useState } from 'react'
import "./styles.css";

export default function EmptyButton({children, onClick, color}) {
    const [hovering, setHovering] = useState(false);

    const styles = {
        button: {
            margin: 10,
            fontWeight: 'bold',
            backgroundColor: hovering && "transparent",
            color: !hovering && color,
            height: '6em'
        }
    }

    return (
        <>
            <Button onClick={onClick} className={hovering && "border"} style={styles.button} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} size='large'>{children}</Button>
    </>
    )
}