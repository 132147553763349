import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { Colors } from '../constants/Colors';

export default function LoadingOverlay({visible}) {


    return (
        <Modal open={visible} style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress
                        size={63}
                        style={{
                        color: Colors.primary500,
                        }}
                    />
        </Modal>
    );
};