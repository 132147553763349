import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { Colors } from "../constants/Colors";
import FilledButton from "./UI/FilledButton";

export default function AlertModal({ visible, onClose, message }) {
  const modal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: 4,
  };

  return (
    <>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={visible}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={visible}>
        <Box sx={modal}>
        <p style={{fontWeight: 'bold', color: Colors.primary1600, fontSize: 25, textAlign: 'center'}}>Contacted!</p>
          <p style={{fontWeight: 'bold', textAlign: 'center',}}>
            {message}
          </p>
          <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
            <FilledButton onClick={onClose}>Okay</FilledButton>
          </div>
        </Box>
      </Fade>
    </Modal>
  </>
  );
}
