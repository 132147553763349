import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import EmptyButton from '../../componenets/UI/EmptyButton';
import { Navigate } from 'react-router-dom';
import DrawerComponent from '../../componenets/Drawer';
import { Colors } from '../../constants/Colors';
import LoadingOverlay from '../../componenets/LoadingOverlay';

export default function AboutScreen() {
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);
    const [navigate, setNavigate] = useState('');
    const [loading, setLoading] = useState(false);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);



    if (navigate !== "") {
        return <Navigate to={navigate}/>
    }

    const styles = {
        header: {
            flexDirection: 'row', 
            display: 'flex', 
            width: '100vw',
            boxShadow: "0px 5px 10px rgb(0 0 0 / 0.2)",
            backgroundColor: 'lightgray',
            position: 'fixed'
        },
        toolbar: {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            flex: 1
        },
        toolbarTitle: {
            textAlign: 'center', 
            color: 'navy'
        },
        toolbarButtonsContainer: {
            marginLeft: 50, 
            marginTop: 5
        },
        toolbarButton: {
            marginRight: 15, 
            fontSize: 20,
            color: 'navy'
        },
        vibrantText: {
            color: 'black', 
            fontSize: 30,
            fontWeight: 'bold',
            color: Colors.primary1100,
            margin: windowSize[0] < 800 && 0
        },
    }

    return (
        <>
        <div style={styles.header}>
            <IconButton style={{marginLeft: 15}} onClick={() => setDrawerIsVisible(true)}>
                <MenuIcon style={{color: 'navy'}}/>
            </IconButton>
            <div style={{display: 'flex', justifyContent: windowSize[0] > 1000 ? 'flex-end' : 'center', width: windowSize[0] > 1000 ? '20%' : '73%'}}>
                <img src={require('../../assets/breddilogo.png')} style={{width: 175}}/>
            </div>
            {windowSize[0] > 1000 && <div style={styles.toolbar}>
                <div style={styles.toolbarButtonsContainer}>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(true);
                                setNavigate('/')
                            }, 650)
                        }} color='navy' >Home</EmptyButton>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/contact'), 650)
                            
                        }} color='navy' >Contact</EmptyButton>
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/past-transactions'), 650)
                            
                        }} color='navy' >Past Transactions</EmptyButton>      
                        <EmptyButton onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/home-services'), 650)
                            
                        }} color='navy' >Home Services</EmptyButton>                     
                        <EmptyButton color='navy'  onClick={() => {
                            setLoading(true);
                            setTimeout(() => setNavigate('/listings'), 650)
                            
                        }}>LISTINGS</EmptyButton>
                    </div>
            </div>}
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: windowSize[0] > 800 &&'85vh'}}>
            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: (windowSize[0] < 800 && windowSize[0] > 500) && 35, marginRight: windowSize[0] < 500 && 15 }}>
                <div style={{display: 'flex', flexDirection: windowSize[0] < 800 ? 'column' : 'row', margin: windowSize[0] > 800 && 25, marginBottom: windowSize[0] < 800 && 15, marginTop: windowSize[0] < 800 && 25, marginRight: windowSize[0] < 800 && 10, marginLeft: windowSize[0] < 800 && 10, alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{marginTop: windowSize[0] < 900 && '7em', marginLeft: windowSize[0] < 800 && 15, width: windowSize[0] < 800 ? '103%' : (windowSize[0] > 800 && windowSize[0] < 1200) ? '50%' : '30%', marginRight: windowSize[0] > 800 && 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <h2 style={styles.vibrantText}>About Me</h2>
                        <p style={{textAlign: windowSize[0] < 800 ? 'justify' : 'left', fontWeight: '500', fontSize: 18, marginLeft: windowSize[0] > 1000 && 30}}>
                            I have a profound passion for connecting individuals with
                            their ideal properties. I am a seasoned realtor who
                            possesses an unrivaled understanding of the market. Armed with an
                            extensive knowledge of local neighborhoods, trends, and a keen eye
                            for detail, I go above and beyond to ensure every
                            client's unique needs are met with utmost care and precision. Trust,
                            integrity, and personalized attention are the cornerstones of
                            my approach, ensuring that each customer receives
                            tailored guidance throughout the entire home-buying journey.
                            Discover a partnership that not only guarantees seamless
                            transactions but also transforms the process into an exciting and
                            rewarding experience. <span style={{color: Colors.primary1100, fontWeight: 'bold'}}>Let me unlock the doors to your
                            dream home today!</span>
                        </p>
                    </div>
                    <img src={require('../../assets/breddy.png')} style={{width: 200, borderRadius: 8}}/>
                </div>
            </div>
        </div>
        <DrawerComponent visible={drawerIsVisible} onCloseDrawer={() => setDrawerIsVisible(false)} activeItem="about"/>
        <LoadingOverlay visible={loading}/>
        </>
    )
};